<template>
    <canvas ref="flow-chart-ref" id="flow-chart"></canvas>
</template>
<script>
import Chart from 'chart.js';

const initChart = function (vm, data, labels) {
  const chart = new Chart(
      document.getElementById('flow-chart').getContext('2d'),
      {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ['#A3A1FB', '#EDECFE',],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio:true,
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
            mode: 'single',
          },
        },
        plugins: [{
          beforeDraw: function (chart) {
            const height = chart.chart.height;
            const ctx = chart.chart.ctx;

            const fontSize = (height / 120).toFixed(2);
            ctx.restore();
            ctx.font = fontSize + "em Avenir";
            ctx.fillStyle ="#4D4F5C";
            ctx.textBaseline = "middle";
            const reducer = (accumulator, currentValue) => accumulator + currentValue;

            const sumData = Math.round(data.reduce(reducer));

            const textBottom = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sumData);

            const width = chart.chart.width;

            const text = 'Total';
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2.33;

            let widthBottom = chart.chart.width;

            if (textBottom.length < 9) {
              widthBottom = chart.chart.width - textBottom.length * 2;
            } else {
              widthBottom = chart.chart.width - textBottom.length * 3;
            }


            const textBottomX = Math.round((widthBottom - ctx.measureText(text).width) / 2);
            const textBottomY = height / 1.7;

            ctx.fillText(text, textX, textY);
            ctx.fillText(textBottom, textBottomX, textBottomY);
            ctx.save();
          },
        }],
      }
  );
  chart.render();
};

export default {
  data: () => ({
    innerWidth: 1600,
    pieWidth: 100,
    pieHeight: 180,
    index: 0,
    renderComponent: true,
  }),

  methods: {
    reloadChart() {
        const data = [1000 , 975.40];
        const labels = ['Paiement en ligne', 'Prélèvements SEPA'];
        initChart(this, data, labels);
    },
    regenerateCanvas() {
      $("#flow-chart").empty();
      let newCanvas = document.createElement('canvas');
      newCanvas.setAttribute("id", "flow-chart");
      $('#flow-chart-container').html(newCanvas);
    },
  },
  mounted () {
    this.$nextTick(function () {
      this.reloadChart();
    });
  },
  destroyed() {

  }
};
</script>
